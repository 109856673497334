<template>
  <div class="dev-item">
    <div class="m-title">
      <!-- PolySmartChain桥 -->
      {{ $t("study.bridgeT") }}
    </div>
    <div class="m-p">
      <!-- 区块链桥梁就像我们在物理世界中所知道的桥梁一样工作。正如物理桥梁连接两个物理位置一样，区块链桥梁连接两个区块链生态系统。桥梁通过信息和资产的传输促进区块链之间的通信。 -->

      {{ $t("study.bridgeP") }}
    </div>
    <div class="m-but2" @click="uti.goPath(store.link.study.bridgeBut)">
      <!-- 探索PSC桥 -->
      {{ $t("study.bridgeB") }}
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  height: (811.67 / 3.75vw);
  background-image: url("../../assets/images/v4/study/m/bridgeBg.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  text-align: center;
  padding-top: (185 / 3.75vw);
  .m-p {
    margin-top: (87 / 3.75vw);
    padding: 0 (14 / 3.75vw);
  }
  .m-but2 {
    margin-top: (82 / 3.75vw);
  }
}
</style>
